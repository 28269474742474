import { initFederation } from '@angular-architects/native-federation';
import { environment } from './environments/environment';
import { initMFs, registerMFDebug } from './mf.utils';

initMFs();

initFederation(environment.MFE_MANIFEST_URL)
    .catch(err => console.error(err))
    .then(() => import('./bootstrap'))
    .catch(err => console.error(err))
    .then(() => registerMFDebug());
